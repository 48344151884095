import React from 'react';

import { Box, Image } from 'grommet';

const LOGO_HEIGHT = 100;

const MenuHeader = ({header = {}, selected, onClick, emptyLogo }) => {

  const { logo } = header;

  return (
    <Box alignContent="center" align="center" background={selected ? 'accent-1' : 'transparent'} onClick={onClick}>
      <Box pad="medium">
        {
          logo &&
          <Image
            fit="cover"
            height={LOGO_HEIGHT}
            src={logo}
          />
        }
        {
          !logo &&
          emptyLogo
        }
      </Box>
    </Box>
  );
};

export default MenuHeader;
