export const formatPrice = (price, decimal = true) => {

  if( price === 'market' ) {

    return price;
  }

  if( price < 0 ) {

    return '';
  }

  if( !decimal ) {

    return Number( price ).toFixed( 0 );
  }

  return Number( price ).toFixed( 2 );
}
