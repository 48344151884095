import React, { useContext } from 'react';

import { Box } from 'grommet';

import MenuContext from './MenuContext';

import MenuText from './MenuText';

const Section = ({section, selected, onClick, extra }) => {

  const { theme } = useContext( MenuContext ) || {};

  const formatting = theme.section;

  return (
    <Box
      onClick={onClick}
      background={selected ? 'accent-1' : 'transparent'}
      style={{userSelect: 'none'}}
      margin={{top: formatting.before || 'none', bottom: formatting.after || 'none'}}
      direction="row"
    >
      <Box pad={formatting.pad || "small" } flex>
        <p style={{textAlign: formatting.align || 'left', padding: 0, margin: 0 }}>
          <MenuText value={section.name} formatting={formatting}/>
        </p>
      </Box>
      {extra}
    </Box>
  );
};

export default Section;
