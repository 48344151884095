
const modernTheme = {

  meta: {

    name: 'Modern',
  },

  background: {

    color: 'white'
  },

  section: {

    align: 'center',
    case: 'caps',
    color: 'black',
    weight: 'bold',
    size: 'xxlarge',
  },

  item: {

    layout: [
      {
        align: 'center',
        lineHeight: 1.6,
        parts: [
          {
            name: 'name',
            case: 'normal',
            size: 'large',
            color: 'neutral-2',
            weight: 'bold',
          },
          {
            name: 'desc',
            prefix: ' ',
            case: 'normal',
            size: 'large',
            color: 'black',
          },
          {
            name: 'price',
            prefix: ' ',
            color: 'black',
            weight: 'bold',
            size: 'large',
            type: 'price',
          }
        ],
      },
    ]
  },
};

const bistroTheme = {

  meta: {

    name: 'Bistro',
  },

  background: {

    color: 'white'
  },

  section: {

    before: 'medium',
    align: 'left',
    case: 'caps',
    color: 'black',
    weight: 'bold',
    size: 'xxlarge',
  },

  item: {

    layout: [
      {
        align: 'left',
        lineHeight: 1.6,
        parts: [
          {
            name: 'name',
            case: 'caps',
            color: 'neutral-2',
            size: 'large',
            weight: 'bold',
          },
          {
            name: 'price',
            prefix: ' ',
            color: 'black',
            weight: 'bold',
            size: 'large',
            type: 'price',
            decimal: false,
          }
        ],
      },
      {
        align: 'left',
        parts: [
          {
            name: 'desc',
            case: 'normal',
            size: 'large',
            color: 'black',
          },
        ]
      }
    ]
  },
};

const simpleTheme = {

  meta: {

    name: 'Simple',
  },

  background: {

    color: 'white'
  },

  section: {

    align: 'left',
    case: 'normal',
    color: 'black',
    weight: 'bold',
    size: 'xxlarge',
  },

  item: {

    layout: [
      {
        align: 'left',
        parts: [
          {
            name: 'name',
            case: 'normal',
            size: 'large',
            color: 'black',
            weight: 'bold',
          }
        ],
      },
      {
        align: 'left',
        parts: [
          {
            name: 'desc',
            case: 'normal',
            size: 'large',
            color: 'black',
          },
        ],
      },
      {
        align: 'left',
        parts: [
          {
            name: 'price',
            color: 'black',
            size: 'large',
            weight: 'bold',
            type: 'price',
          }
        ],
      }
    ],
  }
}

export const themes = {

  simple: simpleTheme,
  modern: modernTheme,
  bistro: bistroTheme,
};

export default themes;
