import React from 'react';

import { Text } from 'grommet';

import { formatPrice } from '../lib/formatters';

const CASE_FORMATTER = {

  caps: (value) => value ? value.toUpperCase() : value,
  normal: (value) => value,
};

  //

const changeCase = ( value, formatting ) => {

  const { case: caseType = 'normal' } = formatting;

  const formatter = CASE_FORMATTER[ caseType ] || CASE_FORMATTER.normal;

  return formatter( value );
};

const processValue = ( value, formatting ) => {

  if( formatting.type === 'price' ) {

    const isPriceValid = (value && (value === 'market' || !isNaN( +value )) );

    const { decimal = true } = formatting;

    if( isPriceValid ) {

      return formatPrice( value, decimal );
    }
  }

  return value;
}

const MenuText = ({value, formatting}) => {

  if( !value ) {

    return null;
  }

  value = processValue( value, formatting );
  value = changeCase( value, formatting );

  const valueString = formatting.prefix ? `${formatting.prefix}${value}` : value;

  return (
    <Text size={formatting.size || 'medium'} color={ formatting.color || 'black' } weight={formatting.weight || 'normal'}>
      {valueString}
    </Text>
  );
};

export default MenuText;
