import React, { useContext } from 'react';

import { Box } from 'grommet';

import MenuContext from './MenuContext';

import MenuText from './MenuText';

const MenuItemLine = ({item, line}) => {

  return (
    <Box pad="xxsmall">
      <p style={{textAlign: line.align || 'left', lineHeight: line.lineHeight || 1.3, padding: 0, margin: 0}}>
      {
        line.parts.map( (part, index ) => (<MenuText key={`item:${index}`} value={item[ part.name ]} formatting={part}/>) )
      }
      </p>
    </Box>
  )
};

const MenuItem = ({item, separatorBefore, selected, onClick, extra = null}) => {

  const { theme } = useContext( MenuContext ) || {};

  const { layout } = theme.item;

  return (
    <Box
      direction="row"
      background={selected ? 'accent-1' : 'transparent'}
      style={{userSelect: 'none'}}
      onClick={onClick}
    >
      <Box pad="small" justify="stretch" flex>
        {
          layout.map( (line, index) => <MenuItemLine key={`${item.id}-${index}`} item={item} line={line}/>)
        }
      </Box>
      {extra}
    </Box>
  );
};

export default MenuItem;
